import React from "react"
import { graphql } from "gatsby"
import { Global } from "@emotion/react"
import { css } from "@emotion/core"

import { Layout } from "../components/layout"

export default ({ data }) => {
  const html = data.allPrismicServices.nodes[0].data.services.html
  return (
    <>
      <Layout>
        <div
          className="rich-text"
          dangerouslySetInnerHTML={{ __html: html }}
          css={{ margin: "0 auto", width: "90vw", maxWidth: "48rem" }}
        ></div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ServicesPageQuery {
    allPrismicServices {
      nodes {
        data {
          services {
            html
          }
        }
      }
    }
  }
`
